import {
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
  FaMastodon,
  FaReddit,
} from "react-icons/fa";

import {
  SiGravatar,
  // SiElement
  // SiMatrix
} from "@icons-pack/react-simple-icons";

import "./App.css";

function App() {
  const main = {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#282c34",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const h1 = {
    fontSize: "80px",
  };

  const h2 = {
    fontSize: "45px",
    width: "80%",
    marginTop: "0px",
  };

  const h6 = {
    fontSize: "25px",
    color: "white",
    marginTop: "20px",
  };

  const listitem = {
    fontSize: "20px",
    color: "white",
    padding: "15px",
  };

  const iconStyle = {
    marginRight: "10px",
    color: "white",
  };

  const spacer = {
    marginTop: "20px",
  };

  const bigspacer = {
    marginTop: "50px",
  };

  return (
    <div style={main} className="App">
      <header className="App-header">
        <h1 style={h1}>👋</h1>

        <h2 style={h2}>Hi! I'm Anton, a software engineer from Berlin.</h2>

        <h6 style={h6}>My links</h6>

        <a style={listitem} href="https://productivity.antonengelhardt.de">
          Productivity guide on Notion
        </a>

        <a style={listitem} href="https://github.com/antonengelhardt/wasm-oidc-plugin">
          wasm-oidc-plugin: Envoy plugin using OIDC to authenticate requests, written in Rust (my bachelor thesis project)
        </a>

        <a style={listitem} href="https://github.com/antonengelhardt/kicktipp-bot">
          kicktipp-bot: Tips for your kicktipp.de football matches
        </a>

        <a style={listitem} href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
          Password List (do not share!!!)
        </a>

        {/* <a
          style={listitem}
          href="https://keys.openpgp.org/search?q=antoncengelhardt%40icloud.com"
        >
          My PGP key
        </a> */}

        <div style={spacer}></div>
        <div style={spacer}></div>

        <div className="socials" style={spacer}>
          <a
            href="https://github.com/antonengelhardt"
            style={iconStyle}
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub size={40} />
          </a>

          <a
            href="https://www.instagram.com/anton.engelhardt"
            style={iconStyle}
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram size={40} />
          </a>

          <a
            href="https://www.reddit.com/user/howigotdrunk"
            style={iconStyle}
            target="_blank"
            rel="noreferrer"
          >
            <FaReddit size={40} />
          </a>

          <a
            href="https://www.linkedin.com/in/anton-engelhardt-a428b3169/"
            style={iconStyle}
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn size={40} />
          </a>

          <a
            href="https://mastodon.ae02.de/@ae"
            style={iconStyle}
            target="_blank"
            rel="noreferrer"
          >
            <FaMastodon size={40} />
          </a>

          <a
            href="https://gravatar.com/antonengelhardt"
            style={iconStyle}
            target="_blank"
            rel="noreferrer"
          >
            <SiGravatar size={40} />
          </a>

          {/* <a
            href="https://matrix.to/#/@antonengelhardt:matrix.antonengelhardt.de"
            style={iconStyle}
            target="_blank"
            rel="noreferrer"
          >
            <SiMatrix size={40} />
          </a> */}

          <div style={bigspacer}></div>
        </div>
      </header>
    </div>
  );
}

export default App;
